<template>
    <div style="margin-top: 10px">
        <el-tabs v-model="accountId" type="card" @tab-click="selectTab">
            <el-tab-pane :label="a.name" v-for="a in accounts" :name="String(a.id)" :key="a.id"></el-tab-pane>
        </el-tabs>
        <el-row>
            <el-col :xs="24">
                <h3>Summary</h3>
                <el-table :data="summary" v-loading="accountLoading">
                    <el-table-column label="Days" prop="days" width="100">

                    </el-table-column>
                    <el-table-column label="Annualized returns" prop="annualized_returns" width="150"></el-table-column>
                    <!--                     <el-table-column label="Max Drawdown" prop="min_net" width="150"></el-table-column>-->
                    <el-table-column label="Sharp" prop="sharp_ratio" width="150"></el-table-column>
                    <el-table-column label="Net" prop="net" width="150"></el-table-column>
                    <el-table-column label="Total" prop="total" width="150"></el-table-column>
                    <!--                     <el-table-column label="300 Max Drawdown" prop="hs300_min_net" width="200"></el-table-column>-->
                    <el-table-column label="300 Sharp" prop="hs300_sharp_ratio" width="150"></el-table-column>
                    <el-table-column label="300 Net" prop="hs300_net" width="150"></el-table-column>
                </el-table>
            </el-col>
            <el-col :xs="24" :sm="12" :md="10" :lg="8" :xl="6">
                <h3>Position</h3>
                <el-table :data="position" border stripe v-loading="accountLoading">
                    <el-table-column label="Code" width="70">
                        <template slot-scope="scope">
                            <el-tooltip :content="scope.row.name">
                                <span>{{scope.row.code}}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="Amount/Avail" min-width="120">
                        <template slot-scope="scope">
                            {{scope.row.amount}}/{{scope.row.available_amount}}
                        </template>
                    </el-table-column>
                    <el-table-column label="Total" prop="total" min-width="80">
                    </el-table-column>
                    <el-table-column label="Profit/Ratio" min-width="150">
                        <template slot-scope="scope">
                            <color-number :num="scope.row.profit"></color-number>
                            /
                            <color-number :num="scope.row.profit_ratio" rate></color-number>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
            <el-col :xs="24" :sm="12" :md="14" :lg="16" :xl="18" style="padding-top: 3px">
                <ve-line
                        :data="chartData"
                        :colors="chartOptions.colors"
                        :tooltip="chartOptions.tooltip"
                        :grid="chartOptions.grid"
                        :xAxis="chartOptions.xAxis"
                        :yAxis="chartOptions.yAxis"
                ></ve-line>
            </el-col>
        </el-row>
        <h3>Balance</h3>
        <el-table :data="history" border stripe v-loading="accountLoading">
            <el-table-column label="Date" prop="date" width="100"></el-table-column>
            <el-table-column label="Shares" prop="shares"></el-table-column>
            <el-table-column label="Avail" prop="available_balance"></el-table-column>
            <el-table-column label="Market" prop="market_balance"></el-table-column>
            <el-table-column label="Total" prop="total" min-width="100"></el-table-column>
            <el-table-column label="DEP/WD" min-width="100">
                <template slot-scope="scope">
                    <color-number :num="scope.row.deposit_withdraw"></color-number>
                </template>
            </el-table-column>
            <el-table-column label="Change">
                <template slot-scope="scope">
                    <color-number :num="scope.row.change" :compare="scope.row.hs300_change" rate></color-number>
                </template>
            </el-table-column>
            <el-table-column label="Net">
                <template slot-scope="scope">
                    <color-number :num="scope.row.net" :compare="scope.row.hs300_net" rate></color-number>
                </template>
            </el-table-column>
            <el-table-column label="Sharp">
                <template slot-scope="scope">
                    <color-number :num="scope.row.sharp_ratio" :compare="scope.row.hs300_sharp_ratio" nosign></color-number>
                </template>
            </el-table-column>
            <el-table-column label="300 Sharp">
                <template slot-scope="scope">
                    <color-number :num="scope.row.hs300_sharp_ratio" :compare="scope.row.sharp_ratio" nosign></color-number>
                </template>
            </el-table-column>
            <el-table-column label="300 Change">
                <template slot-scope="scope">
                    <color-number :num="scope.row.hs300_change" rate></color-number>
                </template>
            </el-table-column>
            <el-table-column label="300 Net">
                <template slot-scope="scope">
                    <color-number :num="scope.row.hs300_net" rate></color-number>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import api from '@/common/api'
    import ColorNumber from '@/components/ColorNumber'

    export default {
        components: {
            ColorNumber
        },
        mounted() {
            api.get('balance/account').then(accounts => {
                this.accounts = accounts
                if (accounts.length > 0) {
                    this.accountId = String(accounts[0].id)
                    this.selectTab({name: this.accountId})
                }
            })
        },
        data() {
            return {
                accountId: null,
                accountLoading: false,
                accounts: [],
                position: [],
                history: [],
                summary: [],
                chartData: {
                    columns: ['date', 'net', 'hs300'],
                    rows: []
                },
                chartOptions: {
                    colors: ['#F56C6C', '#409EFF'],
                    grid: {
                        show: true,
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        formatter: (params) => {
                            var display = params[0].name + '<br>';
                            params.map(function (row) {
                                display += row.seriesName + ': ' + String(Math.round(Number(row.value[1]) * 10000) / 100) + ' %<br>';
                            });
                            return display;
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        scale: true,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        scale: true,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                        axisLabel: {
                            formatter: (value) => {
                                return String(Math.round(value * 10000) / 100) + " %";
                            }
                        }
                    },
                },
            }
        },
        methods: {
            selectTab(tab) {
                this.history = []
                this.position = []
                this.accountLoading = true
                api.get(`balance/account/${tab.name}`).then(res => {
                    this.position = res.position
                    let total_change = 0
                    this.chartData.rows = res.history.map((i) => {
                        total_change += i.change
                        i.date = i.date.slice(0,10)
                        return {
                            date: i.date,
                            net: i.net,
                            hs300: i.hs300_net,
                        }
                    })
                    this.history = res.history.reverse()
                    this.accountLoading = false
                    // console.log(total_change / this.history.length)
                    this.summary = [{
                        days: this.history.length,
                        sharp_ratio: this.history[0].sharp_ratio.toFixed(2),
                        annualized_returns: (((total_change / this.history.length + 1) ** 250 - 1) * 100).toFixed(2) + '%',
                        net: (1 + this.history[0].net).toFixed(3),
                        min_net: (this.history[0].min_net * 100).toFixed(2) + '%',
                        hs300_min_net: (this.history[0].hs300_min_net * 100).toFixed(2) + '%',
                        total: this.history[0].total,
                        hs300_sharp_ratio: this.history[0].hs300_sharp_ratio.toFixed(2),
                        hs300_net: (1 + this.history[0].hs300_net).toFixed(3),
                    }]
                }).catch(() => {
                    this.accountLoading = false
                })
            }
        }
    }
</script>

<style scoped>

</style>
