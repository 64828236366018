<template>
    <span :class="className">{{symbol}}{{display}}</span>
</template>

<script>
    export default {
        name: "color-number",
        props: {
            num: Number,
            compare: {
                type: Number,
                default: undefined
            },
            rate: Boolean,
            nosign: Boolean,
        },
        data() {
            return {
                display: null,
                className: null,
                symbol: '',
            }
        },
        mounted() {
            if (this.num > 0) {
                this.className = 'num-red'
                if (!this.nosign) {
                    this.symbol = '+'
                }
            }
            if (this.num < 0) {
                this.className = 'num-green'
            }
            if (this.compare !== undefined) {
                this.className = null
                if (this.num > this.compare) {
                    this.className = 'num-red'
                }
                if (this.num < this.compare) {
                    this.className = 'num-green'
                }
            }
            if (this.rate) {
                this.display = (this.num * 100).toFixed(2) + '%'
                return
            }
            this.display = this.num.toFixed(2)
        }
    }
</script>